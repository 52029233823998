import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import 'react-dates/lib/css/_datepicker.css';

// require specific component styles from @playson-dev/xeye-web-ui-common
import '@playson-dev/xeye-web-ui-common/src/components/Chart/index.scss';
import '@playson-dev/xeye-web-ui-common/src/components/RangeDatePicker/styles.scss';

import '@playson-dev/xeye-web-ui-common/src/assets/scss/index.scss';

import 'assets/scss/overrides.scss';

import { BROADCAST_CHANNEL_NAME } from 'x-common/bundles/AuthBundle/constants';
import BroadcastChannelSubscriber from 'x-common/services/BroadcastChannelSubscriber';
import { ShortenerProvider } from 'x-common/contexts/ShortenerContext';
import { ApiClientProvider } from 'x-common/contexts/ApiClientContext';

import store, { apiClient } from 'store';

import thirdPartyConfigurations from 'utils/thirdPartyConfigurations';

import { TThemeType } from 'x-common/constants';
import { CustomShadowOptions } from 'x-common/themes/customShadows';
import {
  ITypographyColors,
  IIconColors,
  IInfographicColors,
  INotificationColors,
  ISurfaceColors,
  ITextColors,
} from 'x-common/themes/types';

import App from './App';

thirdPartyConfigurations();

const broadcastChannelSubscriber = new BroadcastChannelSubscriber(BROADCAST_CHANNEL_NAME, store);
broadcastChannelSubscriber.addAllSubscriptionPresets();

declare global {
  interface Window {
    store: Store;
  }
}

declare module '@mui/material/styles' {
  interface TypeText {
    hint: string;
  }
  interface Palette {
    infographic: IInfographicColors;
    surfaces: ISurfaceColors;
    icons: IIconColors;
    typography: ITypographyColors;
    notification: INotificationColors;
    text: ITextColors;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    type: TThemeType;
    customShadows: CustomShadowOptions;
    numSpacing: (value: number) => number;
    isDarkTheme: () => boolean;
    isStyledTheme: () => boolean;
    byTheme: ({ light, dark }) => any;
    byThemeType: ({ regular, styled, red }) => any;
  }
  interface ThemeOptions {
    type: TThemeType;
    customShadows: CustomShadowOptions;
    numSpacing: (value: number) => number;
    isDarkTheme: () => boolean;
    isStyledTheme: () => boolean;
    byTheme: ({ light, dark }) => any;
    byThemeType: ({ regular, styled, red }) => any;
  }
}

// plug required by external dependencies for appropriate work
window.process = { env: 'production' } as any;
window.store = store;

render(
  <Provider store={store}>
    <ApiClientProvider apiClient={apiClient}>
      <ShortenerProvider>
        <App />
      </ShortenerProvider>
    </ApiClientProvider>
  </Provider>,
  document.getElementById('root'),
);
